import React, { useState } from "react"
import { useForm } from "react-hook-form"
// import {
//   GoogleReCaptcha,
//   useGoogleReCaptcha
// } from 'react-google-recaptcha-v3';

import EmailInputNewsletter from "../inputs/email-newsletter"
import { Subscribe } from "../../../utils/gtm"

const ENDPOINT = "/api/newsletter/subscribe"

function StayInTheLoop({ classList }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()
  const [result, setResult] = useState("")

  // const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async data => {
    setResult("")
    // if (!executeRecaptcha) {
    //   console.log('Execute recaptcha not yet available');
    //   return;
    // }

    // const token = await executeRecaptcha('STAY_IN_THE_LOOP');
    // Do whatever you want with the token
    try {
      const response = await fetch(ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data }),
      })
      await response.json()
      setResult("Thank you for your registration.")
      Subscribe("footer_newsletter", "email")
    } catch (error) {
      setResult("Sorry, something went wrong, please try again.")
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`${classList}`}
      data-layer-identifier="newsletter-form"
      data-layer-name="newsletter-form"
    >
      <div className="-ml-8">
        <div className="pl-8 flex items-stretch">
          <div className="text-left w-2/3">
            <EmailInputNewsletter
              register={register}
              errors={errors}
              name="email"
              id="email"
              placeholder="name@mail.domain"
            />
          </div>
          <div className="text-left w-1/3">
            {!isSubmitting ? (
              <button
                id="newsletter-subscribe"
                className="btn btn-yellow text-black leading-none text-xs px-3 flex h-full items-center"
                type="submit"
                // data-sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                // data-callback={onSubmit}
                // data-action="submit"
              >
                Submit
              </button>
            ) : (
              <button
                disabled
                className="btn btn-disabled leading-none text-xs px-3 flex h-full items-center"
                type="submit"
              >
                Sending...
              </button>
            )}
          </div>
        </div>

        {errors && errors.email && (
          <p className="pl-8 mb-0 mt-2 text-xs text-yellow-700">
            {errors.email.message}
          </p>
        )}
        {result ? (
          <p className="pl-8 mb-0 mt-2 text-xs text-yellow-700">{result}</p>
        ) : (
          ""
        )}

        {/* <div className="pl-8 w-full mt-8 text-center mx-auto">
          <GoogleReCaptcha />
        </div> */}
      </div>
    </form>
  )
}

export default StayInTheLoop
