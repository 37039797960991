import React, { useState } from "react"
import PropTypes from "prop-types"

function EmailInputNewsletter({
  label,
  register,
  errors,
  name,
  id,
  placeholder,
  displayLabel,
  labelClassList,
  classList,
  borderInput,
  defaultValue,
  labelHeadingList,
}) {
  const [updateValue, setUpdateValue] = useState(defaultValue || "")

  return (
    <div className="relative">
      {displayLabel && (
        <label
          htmlFor={id}
          className={`${
            labelHeadingList || "h4"
          } cursor-pointer block ${labelClassList}`}
        >
          {label}
        </label>
      )}
      <input
        className={`${classList} ${
          errors[name]
            ? "border-05px border-red-500"
            : `${borderInput ? "border-05px border-metal-500" : "border-none"}`
        } px-2 pt-3.5 pb-11px focus:outline-none w-full text-left bg-white text-xs text-black rounded-none relative`}
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        tabIndex={0}
        defaultValue={updateValue}
        onBlur={e => {
          e.target.placeholder = placeholder
        }}
        onFocus={e => {
          e.target.placeholder = ""
        }}
        onChange={e =>
          defaultValue ? setUpdateValue(e.target.value) : e.target.value
        }
        name={id}
        {...register(name, {
          required: "Email required.",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address",
          },
        })}
      />
    </div>
  )
}

EmailInputNewsletter.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  displayLabel: PropTypes.bool,
}

EmailInputNewsletter.defaultProps = {
  errors: {},
  name: "",
  id: "",
  value: "",
  placeholder: "",
  displayLabel: false,
}

export default EmailInputNewsletter
